/**
* Template Name: Impact
* Template URL: https://bootstrapmade.com/impact-bootstrap-business-website-template/
* Updated: Jun 14 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Montserrat",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root { 
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #222222; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #172a28; /* Color for headings, subheadings and title throughout the website */
  --accent-color: rgb(67, 67, 238); /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --contrast-color: #ffffff; /* The contrast color is used for elements when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #ffff;  /* The default color of the main navmenu links */
  --nav-hover-color: green; /* Applied to main navmenu links when they are hovered over or active */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: rgb(67, 67, 238); /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: green; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  --background-color: #340346;
  --heading-color: #ffffff;
  color: var(--default-color);
  transition: all 0.5s;
  z-index: 997;
  background-color: var(--background-color);
}

.header .topbar {
  background-color: color-mix(in srgb, var(--accent-color) 90%, black 10%);
  height: 40px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
}

.header .topbar .contact-info i {
  font-style: normal;
  color: var(--contrast-color);
}

.header .topbar .contact-info i a,
.header .topbar .contact-info i span {
  padding-left: 5px;
  color: var(--contrast-color);
}

@media (max-width: 575px) {

  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    font-size: 13px;
  }
}

.header .topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

.header .topbar .contact-info i a:hover {
  color: var(--contrast-color);
  text-decoration: underline;
}

.header .topbar .social-links a {
  color: color-mix(in srgb, var(--contrast-color), transparent 40%);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

.header .topbar .social-links a:hover {
  color: var(--contrast-color);
}

.header .branding {
  min-height: 60px;
  padding: 10px 0;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 80px;
}

.header .logo h1 {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .logo span {
  font-size: 32px;
  margin-left: 2px;
  color: var(--nav-hover-color);
}

.scrolled .header {
  box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu>ul>li {
    white-space: nowrap;
    padding: 15px 14px;
  }

  .navmenu>ul>li:last-child {
    padding-right: 0;
  }

  .navmenu a,
  .navmenu a:focus {
    
    color: var(--nav-color);
    font-size: 20px;
    padding: 0 2px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 15px;
    line-height: 0;
    margin-left: 2px;
    transition: 0.3s;
  }

  .navmenu>ul>li>a:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: var(--nav-hover-color);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navmenu a:hover:before,
  .navmenu li:hover>a:before,
  .navmenu .active:before {
    visibility: visible;
    width: 100%;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
  }
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  --background-color: var(--accent-color);
  --default-color: #fff;
  --heading-color: #fff;
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  position: relative;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--default-color);
  border-color: var(--default-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--default-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 4px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: var(--background-color);
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-color: var(--accent-color) transparent var(--accent-color) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: visible;
  opacity: 1;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: green;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.7s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, green, transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  --background-color: var(--accent-color);
  --default-color: #fff;
  --heading-color: #fff;
  color: var(--default-color);
  background-color: var(--background-color);
  position: relative;
}

.page-title .heading {
  padding: 160px 0 80px 0;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.page-title nav {
  background-color: color-mix(in srgb, var(--default-color), transparent 92%);
  padding: 20px 0;
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol a {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.page-title nav ol a:hover {
  color: var(--default-color);
}

.page-title nav ol li+li {
  padding-left: 10px;
}

.page-title nav ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 112px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 100px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  --background-color: var(--accent-color);
  --default-color: #ffffff;
  --heading-color: #ffffff;
  width: 100%;
  position: relative;
  padding: 140px 0 0 0;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero h2 .accent {
  color: var(--conrast-color);
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-weight: 400;
  margin-bottom: 30px;
}

.hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 40px;
  border-radius: 50px;
  transition: 0.3s;
  border: 2px solid color-mix(in srgb, var(--default-color), transparent 90%);
  background-color: color-mix(in srgb, var(--default-color), transparent 90%);
}

.hero .btn-get-started:hover {
  border-color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  color: var(--default-color);
}

.hero .btn-watch-video i {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover i {
  color: var(--default-color);
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }
}

.hero .icon-boxes {
  padding-bottom: 60px;
  z-index: 4;
}

@media (min-width: 1200px) {
  .hero .icon-boxes:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50% + 20px);
    background-color: var(--contrast-color);
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
}
@media (min-width: 1200px) {
  .col-xl-3 {
      flex: 0 0 auto;
      width: 20% !important;
  }
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1300px !important;
  }
}

.hero .icon-box {
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, rgb(51, 145, 39) 43%, rgba(0, 13, 255, 0.856) 72%);
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  text-align: center;
}

.hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.hero .icon-box .title a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.hero .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.hero .icon-box:hover {
  background-color: color-mix(in srgb, var(--accent-color), white 10%);
}

.hero .icon-box:hover .title a,
.hero .icon-box:hover .icon {
  color: rgb(255, 255, 255);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
}

.about .fst-italic {
  color: color-mix(in srgb, var(--default-color), var(--contrast-color) 50%);
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--accent-color);
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 75%) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients .swiper {
  padding: 10px 0;
}

.clients .swiper-wrapper {
  height: auto;
}

.clients .swiper-slide img {
  transition: 0.3s;
}

.clients .swiper-slide img:hover {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Stats Section
--------------------------------------------------------------*/
.stats .stats-item {
  padding: 10px;
}

.stats .stats-item i {
  font-size: 44px;
  color: var(--accent-color);
  line-height: 0;
  margin-right: 15px;
}

.stats .stats-item .purecounter {
  color: var(--heading-color);
  font-size: 40px;
  display: block;
  font-weight: 700;
  line-height: 40px;
}

.stats .stats-item p {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 15px 0 0 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  --default-color: #ffffff;
  --contrast-color: #ffffff;
}

.call-to-action .container {
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  clip-path: inset(0 round 15px);
}

.call-to-action .container img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
}

.call-to-action .container:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.call-to-action .container .content {
  position: relative;
  z-index: 3;
}

.call-to-action h3 {
  color: var(--default-color);
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.call-to-action p {
  color: var(--default-color);
  margin-bottom: 20px;
}

.call-to-action .play-btn {
  width: 94px;
  height: 94px;
  margin-bottom: 20px;
  background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 60%) 52%);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.call-to-action .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 20%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.call-to-action .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--default-color);
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.call-to-action .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--default-color);
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.call-to-action .play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

.call-to-action .cta-btn {
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--contrast-color);
  color: var(--contrast-color);
}

.call-to-action .cta-btn:hover {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  padding: 40px;
  box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 90%);
  height: 100%;
}

.services .service-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-bottom: 50px;
}

.services .service-item .icon i {
  color: color-mix(in srgb, var(--heading-color), transparent 30%);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 150%;
  width: 150%;
  background: color-mix(in srgb, var(--heading-color), transparent 95%);
  border-radius: 50px;
  z-index: 1;
  top: 5px;
  right: -15px;
  transition: 0.3s;
}

.services .service-item h3 {
  color: color-mix(in srgb, var(--heading-color), transparent 20%);
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  border-bottom: 4px solid color-mix(in srgb, var(--heading-color), transparent 90%);
  transition: 0.3s;
}

.services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.services .service-item:hover .icon i {
  color: var(--heading-color);
}

.services .service-item:hover .icon:before {
  background: color-mix(in srgb, var(--accent-color), transparent 50%);
}

.services .service-item:hover h3 {
  border-color: color-mix(in srgb, var(--accent-color), transparent 10%);
  color: var(--heading-color);
}

.services .service-item:hover .readmore {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
  box-sizing: content-box;
  padding: 10px;
  margin: 30px 15px;
  position: relative;
  height: 100%;
  width: 85% !important;
}
.slick-dots li button:before {
font-size: 15px !important;
color: #3a3a3a7e !important;
}
.slick-dots li.slick-active button:before{
  opacity: .75 !important;
  color: blue !important;

}

.testimonials .testimonial-item .testimonial-img {
  width: 80px;
  border-radius: 50px;
  margin-right: 15px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 50%);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-filters {
  padding: 0 0 20px 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin: 0 10px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-filters li:hover,
.portfolio .portfolio-filters li.filter-active {
  color: var(--accent-color);
}

.portfolio .portfolio-filters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-filters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-filters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-content {
  box-shadow: 0px 10px 30px color-mix(in srgb, var(--default-color), transparent 90%);
  height: 100%;
  overflow: hidden;
}

.portfolio .portfolio-content img {
  transition: 0.3s;
  position: relative;
  z-index: 1;
}

.portfolio .portfolio-content .portfolio-info {
  background-color: var(--background-color);
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  padding: 25px 20px;
  position: relative;
  z-index: 2;
}

.portfolio .portfolio-content .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  padding-right: 50px;
}

.portfolio .portfolio-content .portfolio-info h4 a {
  color: var(--heading-color);
  transition: 0.3s;
}

.portfolio .portfolio-content .portfolio-info h4 a:hover {
  color: var(--accent-color);
}

.portfolio .portfolio-content .portfolio-info p {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  margin-bottom: 0;
  padding-right: 50px;
}

.portfolio .portfolio-content:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .member {
  box-shadow: 0px 2px 25px color-mix(in srgb, var(--default-color), transparent 90%);
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  overflow: hidden;
}

.team .member img {
  border-radius: 15px;
  overflow: hidden;
}

.team .member .member-content {
  padding: 0 20px 30px 20px;
}

.team .member h4 {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 2px;
  font-size: 20px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.team .member .social {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.team .member .social a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  transition: 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
}

.team .member .social a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing {
  padding: 60px 0 120px 0;
}

.pricing .section-title {
  margin-bottom: 40px;
}

.pricing .pricing-item {
  box-shadow: 0 3px 20px -2px color-mix(in srgb, var(--default-color), transparent 90%);
  padding: 60px 40px;
  height: 100%;
  position: relative;
  border-radius: 15px;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.pricing .icon {
  margin: 30px auto 20px auto;
  width: 70px;
  height: 70px;
  background: var(--accent-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform-style: preserve-3d;
}

.pricing .icon i {
  color: var(--background-color);
  font-size: 28px;
  transition: ease-in-out 0.3s;
  line-height: 0;
}

.pricing .icon::before {
  position: absolute;
  content: "";
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background: color-mix(in srgb, var(--accent-color), transparent 80%);
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.pricing .icon::after {
  position: absolute;
  content: "";
  height: 102px;
  width: 102px;
  border-radius: 50%;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-2px);
}

.pricing h4 {
  font-size: 48px;
  color: var(--accent-color);
  font-weight: 700;
  font-family: var(--heading-font);
  margin-bottom: 25px;
  text-align: center;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 18px;
  font-weight: 400;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.pricing ul .na i {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  background-color: var(--background-color);
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
  transition: none;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--heading-font);
  transition: 0.3s;
}

.pricing .buy-btn:hover {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.pricing .featured {
  z-index: 10;
  border: 3px solid var(--accent-color);
}

@media (min-width: 992px) {
  .pricing .featured {
    transform: scale(1.15);
  }
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.faq .content p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.faq .faq-container .faq-item {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px color-mix(in srgb, var(--default-color), transparent 90%);
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.faq .faq-container .faq-item h3 .num {
  color: var(--accent-color);
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-active h3 {
  color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Recent Posts Section
--------------------------------------------------------------*/
.recent-posts article {
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.recent-posts .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.recent-posts .post-category {
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin-bottom: 10px;
}

.recent-posts .title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.recent-posts .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.recent-posts .title a:hover {
  color: var(--accent-color);
}

.recent-posts .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.recent-posts .post-author {
  font-weight: 600;
  margin-bottom: 5px;
}

.recent-posts .post-date {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-container {
  background-color: var(--accent-color);
  height: 100%;
  padding: 20px;
}

.contact .info-item {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--contrast-color), transparent 90%);
}

.contact .info-item:last-child {
  margin-bottom: 0;
}

.contact .info-item i {
  font-size: 20px;
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--contrast-color), transparent 80%);
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  color: var(--contrast-color);
  font-size: 20px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--contrast-color);
  color: var(--accent-color);
}

.contact .php-email-form {
  box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
  width: 100%;
  height: 100%;
  padding: 30px;
}

.contact .php-email-form .error-message {
  
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  
  background: var(--background-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--background-color);
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog Posts Section
--------------------------------------------------------------*/
.blog-posts article {
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blog-posts .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.blog-posts .post-category {
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 10px;
}

.blog-posts .title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog-posts .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.blog-posts .title a:hover {
  color: var(--accent-color);
}

.blog-posts .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog-posts .post-author {
  font-weight: 600;
  margin-bottom: 5px;
}

.blog-posts .post-date {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pagination 2 Section
--------------------------------------------------------------*/
.pagination-2 {
  padding-top: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.pagination-2 ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pagination-2 li {
  margin: 0 5px;
  transition: 0.3s;
}

.pagination-2 li a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-2 li.active,
.pagination-2 li:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.pagination-2 li.active a,
.pagination-2 li:hover a {
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Blog Details Section
--------------------------------------------------------------*/
.blog-details {
  padding-bottom: 30px;
}

.blog-details .article {
  padding: 30px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--accent-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Blog Author Section
--------------------------------------------------------------*/
.blog-author {
  padding: 10px 0 40px 0;
}

.blog-author .author-container {
  padding: 20px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog-author h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog-author .social-links a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-right: 5px;
}

.blog-author p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Comments Section
--------------------------------------------------------------*/
.blog-comments {
  padding-top: 10px;
}

.blog-comments .comments-count {
  font-weight: bold;
}

.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog-comments .comment .comment-img img {
  width: 60px;
}

.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog-comments .comment h5 a {
  font-weight: bold;
  color: var(--default-color);
  transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
  color: var(--accent-color);
}

.blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog-comments .reply-form p {
  font-size: 14px;
}

.blog-comments .reply-form input {
  background-color: var(--background-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 10px;
}

.blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: var(--accent-color);
}

.blog-comments .reply-form textarea {
  background-color: var(--background-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  height: 120px;
}

.blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: var(--accent-color);
}

.blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.blog-comments .reply-form .btn-primary:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid color-mix(in srgb, var(--default-color), transparent 70%);
  margin: 20px 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.service-details .services-list a.active {
  color: var(--heading-color);
  font-weight: 700;
  border-color: var(--accent-color);
}

.service-details .services-list a:hover {
  border-color: var(--accent-color);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Portfolio Details Section
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .swiper-wrapper {
  height: auto;
}

.portfolio-details .swiper-button-prev,
.portfolio-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.portfolio-details .swiper-button-prev:after,
.portfolio-details .swiper-button-next:after {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.15);
  font-size: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.portfolio-details .swiper-button-prev:hover:after,
.portfolio-details .swiper-button-next:hover:after {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 575px) {

  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    display: none;
  }
}

.portfolio-details .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.portfolio-details .portfolio-info h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  bottom: 0;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.portfolio-details .portfolio-info ul strong {
  text-transform: uppercase;
  font-weight: 400;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.portfolio-details .portfolio-info .btn-visit {
  padding: 8px 40px;
  background: var(--accent-color);
  color: var(--contrast-color);
  border-radius: 50px;
  transition: 0.3s;
}

.portfolio-details .portfolio-info .btn-visit:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.portfolio-details .portfolio-description .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: color-mix(in srgb, var(--default-color), transparent 97%);
  margin-bottom: 50px;
}

.portfolio-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid var(--background-color);
  float: left;
  margin: 0 10px 0 0;
}

.portfolio-details .portfolio-description .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 5px 0;
  padding-top: 20px;
}

.portfolio-details .portfolio-description .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 50%);
  font-size: 26px;
  line-height: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.portfolio-details .portfolio-description .testimonial-item p {
  font-style: italic;
  margin: 0 0 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widgets-container {
  padding: 30px;
  margin: 60px 0 30px 0;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget-item {
  margin-bottom: 40px;
}

.widget-item:last-child {
  margin-bottom: 0;
}

.search-widget form {
  background: var(--background-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  padding: 3px 10px;
  position: relative;
}

.search-widget form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
  background-color: var(--background-color);
  color: var(--default-color);
}

.search-widget form input[type=text]:focus {
  outline: none;
}

.search-widget form button {
  background: var(--accent-color);
  color: var(--contrast-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.search-widget form button i {
  line-height: 0;
}

.search-widget form button:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.categories-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget ul li {
  padding-bottom: 10px;
}

.categories-widget ul li:last-child {
  padding-bottom: 0;
}

.categories-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.categories-widget ul a:hover {
  color: var(--accent-color);
}

.categories-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.recent-posts-widget .post-item {
  display: flex;
  margin-bottom: 15px;
}

.recent-posts-widget .post-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item img {
  width: 80px;
  margin-right: 15px;
}

.recent-posts-widget .post-item h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
  color: var(--default-color);
  transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
  color: var(--accent-color);
}

.recent-posts-widget .post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
  margin-bottom: -10px;
}

.tags-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-widget ul li {
  display: inline-block;
}

.tags-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
  display: inline-block;
  transition: 0.3s;
}

.tags-widget ul a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 1px solid var(--accent-color);
}

.tags-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 14px;
}

.services-item1 {
  transition: 0.6s;
}
.text-primary {
  transition: 0.6s !important;
  font-size: 7em !important;
}
.services-item1:hover .text-primary {
  color: #198754 !important;
}
.services-item1:hover {
  box-shadow: 0 0 60px blue;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
}
.rounded-pill {
  transition: 0.7s;
}
.rounded-pill:hover {
  background-color: blue;
}

.swiper-slide .img-fluid{
  
  width: 120px;
  
}
.swiper-slide{
  height: 100px;
}

.rounded-4 {
  margin-left: 5px;
}




@media (max-width: 1199px) {
 .navmenu .mobile-nav-active {
      display: block;
      height: 200px;
  };
}

.imguser{
  border-radius: 50px !important;
  max-height: 50px !important;
  margin-right: 10px !important;
}
.userbg{
  background-color: #059652;
}

@media (min-width: 1200px) {
  .navmenu .userline a:hover:before, .navmenu .userline li:hover>a:before, .navmenu .userline .active:before {
background-color: #ffffff !important;
  }
}


.course-item{
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  border-radius: 7px;
}
.courses .course-content {
  padding: 15px;
}
.courses .course-content .category {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0;
  border-radius: 5px;
}
.courses .course-content .price {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  color: 
color-mix(in srgb, var(--default-color), transparent 20%);
}
.courses .course-content .description {
  font-size: 14px;
  color: 
color-mix(in srgb, var(--default-color), transparent 20%);
}
.courses .trainer {
  padding-top: 15px;
  border-top: 1px solid 
color-mix(in srgb, var(--default-color), transparent 90%);
}
.mehover{
  position: relative;
}
.meimg{
  display: block;
  border-radius: 0px;
}
.mehover:hover .meoverlay {
  opacity: 1;
  border-radius: 0px;
}
.mytext {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}
@media only screen and (max-width: 600px) {
  .mytext{
   display: none;
}
}
.meoverlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008bba8e;
}
.courses .trainer .trainer-profile img {
  max-width: 50px;
  border-radius: 50px;
}
.courses .trainer .trainer-profile .trainer-link {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: 
color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}
.ribbon {
  background-color: #1c9611;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .07);
  border-radius: 0;
  color: #ffffff;
  display: block;
  font-size: 20px;
  position: absolute;
  padding: 7px 15px;
  top: 20px;
  left: -15px;
}
.ribbon:after {
  content: "";
  position: absolute;
  top: auto;
  left: 4px;
  right: auto;
  bottom: -7px;
  background: #026d22;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  height: 20px;
  width: 20px;
  z-index: -1;
}
.enroll{
  color: white !important;
  background: linear-gradient(90deg, #037709, #4706b1) !important;
  cursor: pointer !important;
}
.courses-course-details .course-info {
  background: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 10px 15px;
  margin-bottom: 15px;
}
.courses-course-details h3 {
  font-size: 24px;
  margin: 30px 0 15px 0;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
}
.courses-course-details h3:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: 
color-mix(in srgb, var(--default-color), transparent 90%);
  bottom: 0;
  left: 0;
}
.courses-course-details{
  padding-bottom: 0px;
}
.courses-course-details h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 1px;
  background: var(--accent-color);
  bottom: 0;
  left: 0;
}
.get{
  margin-top: 30px;
}
.getstart {
  background-color: rgb(6, 110, 20);
  padding: 15px 20px;
  color: white !important;
  border-radius: 10px;
  margin-top: 20px !important;
  transition: 0.6s;
}
.getstart:hover{
  background-color: #340346;

}
.nooryesacount{
  background-color: #026d22 !important;
}
.coursecard{
width: auto;
}
/* Add this CSS code to your main CSS file or inside a <style> tag */
.bubbles {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 9999;
}

.bubble {
  position: absolute;
  bottom: 0;
  background-color: rgba(173, 216, 230, 0.7);
  border-radius: 50%;
  animation: rise 5s infinite ease-in;
}

@keyframes rise {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}




.certificate {
  background: #fff;
  border: 2px solid #333;
  padding: 0px;
  text-align: center;
  font-family: 'Georgia', serif;
  width: 100%;
  max-width: 900px;
  max-height: 600px; /* Set the maximum height */
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /*background-image: url('path-to-your-background-image.jpg'); /* Add a background image */
  background-size: cover;
}

.certificate-border {
  border: 10px double #333;
  padding: 2px;
}

.certificate-logo {
  width: 100px;
  margin-bottom: 10px; /* Reduce the bottom margin */
}

.certificate h1 {
  font-size: 28px; /* Reduce the font size */
  margin-bottom: 10px; /* Reduce the bottom margin */
  color: #333;
}

.certificate h2 {
  font-size: 22px; /* Reduce the font size */
  margin: 15px 0; /* Adjust the margin */
  color: #333;
}

.certificate h3 {
  font-size: 18px; /* Reduce the font size */
  margin: 8px 0; /* Adjust the margin */
  color: #333;
}

.certificate p {
  font-size: 16px; /* Reduce the font size */
  margin: 8px 0; /* Adjust the margin */
  color: #026d22;
}
.username{
  text-transform: uppercase;
  text-decoration: underline green;
  color: red !important;
}

.certificate-footer {
  margin-top: 20px; /* Adjust the margin */
  text-align: left;
}

.certificate-footer p {
  font-size: 14px;
  color: #555;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signature {
  font-size: 18px; /* Reduce the font size */
  font-family: 'Brush Script MT', cursive;
  color: #333;
}

.certificate-seal,
.certificate-stamp {
  width: 140px; /* Reduce the width */
  height: 80px; /* Reduce the height */
}

.bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}

.bubble {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: rise 5s infinite;
}

@keyframes rise {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}


.certificate-border {
  border: 4px solid #333; /* Example border style */
  
  position: relative;
  width: 98%; /* Adjust width as needed */
  margin: 0 auto; /* Center the modal */
}

.certificate-border::before,
.certificate-border::after {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid #666; /* Example border style for pseudo elements */
}

.certificate-border::before {
  border-width: 8px;
  z-index: -1;
}

.certificate-border::after {
  border-width: 12px;
  z-index: -2;
}

.certify{
  background-color: #026d22;
  background-color: #026d22;
  /* width: 300px; */
  /* padding: 10px 10px; */
  margin: 0px 30px !important;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
}


/* Add this to your CSS file or inside a <style> tag in your component */

.form-section {
  margin-bottom: 2rem;
}

.form-section h4 {
  margin-bottom: 1rem;
  color: #007bff;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: bold;
}

.form-control:read-only {
  background-color: #e9ecef;
}

.submit-btn {
  margin-top: 1.5rem;
  background-color: #007bff;
  border-color: #007bff;
}

.submit-btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.loading {
  color: blue;
}

.sent-message {
  color: green;
}

.error-message {
  color: red;
}


/* PasswordResetRequestForm.css */

.custom-modal .modal-content {
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.custom-modal .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.custom-modal .modal-body {
  padding-top: 10px;
}

.custom-input {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc;
}

.custom-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.custom-button:hover {
  background-color: #0056b3;
}

.resetp {
  background-color: #037709;
  margin: 10px 120px;
  font-size: 10px;

}

.iso-services {
  font-family: Arial, sans-serif;
}

.iso-services .text-center {
  margin-bottom: 10px;
}

.service-card {
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 10px;
  background-color: #340346;
}


.service-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-card .card-body {
  padding: 30px;
}

.service-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0;
  color: white;
}
.register{
margin-top: 10px;
}

@media (max-width: 767px) {
  .service-card .card-body {
    padding: 15px;
  }

  .service-card .card-title {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .iconboxes {
      flex: 0 0 auto;
      width: 19.93333333%;
  }
}
